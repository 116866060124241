export const defaultMenuType = 'menu-sub-hidden' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = '/app/dashboards/default'
export const searchPath = '/app/pages/search'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
// export const BASE_URL = 'http://10.2.81.170:8080/Avalanche_Full_stack_CB/apis'
// export const BASE_URL = 'http://54.213.237.220/Avalanche_Full_Stack/apis'
//export const BASE_URL = 'http://localhost/bm_vue/apis'
export const BASE_URL = 'https://bluemoonerp.com/bm_vue/apis'
export const CS = 'hqqXVtm6BqCMSSM0xUv0mW6r3b0iVjvCZCibNs2Z'
export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [{
  id: 'en',
  name: 'English LTR',
  direction: 'ltr'
},
{
  id: 'es',
  name: 'Español',
  direction: 'ltr'
},
{
  id: 'enrtl',
  name: 'English RTL',
  direction: 'rtl'
}
]

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563'
}

export const apiUrl = 'https://api.coloredstrategies.com'

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profile-pic-l.jpg',
  date: 'Last seen today 15:24'
}

export const defaultColor = 'light.blue'
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
]
