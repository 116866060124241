<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tender - Results</h2>
       <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
         <div class="text-theme-6 pt-2 mr-2" v-if="status == 1">
            <span>{{timer.days}} Days </span>: <span>{{timer.hours}} Hrs </span>: <span>{{timer.minutes}} Min </span>: <span>{{timer.seconds}} Sec</span>
        </div>

        
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Tender Name : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ tender_name }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Email : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_email }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Mobile No : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_number }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Site Address : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ address }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ start_date_display }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ end_date_display }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_start_date_display }}</label
                  >
                  
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_end_date_display }}</label>
                  
                </div>
              </div>
            </div>
            
        
        </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Bid Results</h2>
        </div>
         
        <div class="border mb-5 pb-5 pt-3" v-if="fields.length > 0">
          <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
                  
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold">
                <p>Lot No</p>
              </div>
             
              <div class="col-span-6 md:col-span-3 xl:col-span-3 font-bold">
                <p>Details</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold">
                <p>Stone</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-1 font-bold">
                <p>Carats</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 font-bold">
              <p>Bid Price($) /carat</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 font-bold">
              <p>Total Price($)</p>
              </div>
               <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold">
                <p>Win/Loss</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold">
                <p>Difference(%)</p>
              </div>
          </div>
          <div class="border mt-5 mb-5" v-for="field in fields" :key="field.id">
            <div class="grid grid-cols-12">
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pb-2 pl-2" >
                <span class="pl-1 pt-3" >{{field.lot_no}}</span>
                
              </div>
            
              <div class="col-span-6 md:col-span-3 xl:col-span-3 border-right pt-2 pb-2 pl-2">
                 <span class="pl-1 pt-3" >{{field.details}}</span>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pb-2 pl-2">
                <span class="pl-1 pt-3" v-if="(field.piece != '') && (field.piece != null) ">{{field.piece}}</span>
                <span class="pl-1 pt-3" v-else>-</span>
             
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pb-2 pl-2">
                <span class="pl-1 pt-3" >{{field.carats}}</span>
              
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 border-right pt-2 pb-2 pl-2">
                <span class="pl-1 pt-3" >{{field.bid_price}}</span>
            
              </div>
              <div class="col-span-6 xl:col-span-2 pt-2 pl-2 pb-2 border-right">
              <span class="pl-1 pt-3">{{ (field.total_item_price).toFixed(2) }}</span>
              </div>
               <div v-if="field.status == 'Loss'" class="col-span-6 md:col-span-1 xl:col-span-1 border-right text-theme-6 pt-2 pl-2 pb-2">
                <span class="pl-1 pt-3" >{{field.status}}</span>
              </div>
              <div v-if="field.status == 'Win'" class="col-span-6 md:col-span-1 xl:col-span-1 border-right text-theme-9 pt-2 pl-2 pb-2">
                <span class="pl-1 pt-3" >{{field.status}}</span>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pl-2 pb-2">
                <span class="pl-1 pt-3" >{{field.dif_per}}</span>
              
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total weight(Carat) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_rough_carat}}</p>
              </div>

          </div>
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total Bid Price($) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_bid_amount}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">4 % Charges ($) : </p>
              </div>
              <div class="col-span-6  md:col-span-2 xl:col-span-2 text-right">
                <p>{{tax_amount}}</p>
              </div>

          </div>

          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total Payable ($) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_amount}}</p>
              </div>

          </div>
        </div>
        <div>
        
          </div>
          
        </div>
        
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { useTimer } from 'vue-timer-hook';
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    }
  },
  
  data() {
      let time = new Date();
    let timer = useTimer(time);

    return {
      timer,
      bid_placed:false,
      tender_update_id : '',
      tender_name : '',
      contact_number: '',
      contact_email: '',
      total_rough_carat : 0,
      total_bid_amount: 0,
      tax_amount: 0,
      total_amount: 0,
      address: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      total_items:1,
      fields: [],
     
      auction_status : 'no',
      status: 'default',
      winner_an:'',
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
mounted(){
  
  this.getTenderDetails(this.tender_id);
  },

  methods: {
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/buyer_tender_results_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          let tender_detail = data.tender_detail;

          let time = new Date(tender_detail.bid_end_time);
          
          this.timer.restart(time);

          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.result_details;
          this.contact_number= tender_detail.contact_number;
            this.contact_email= tender_detail.contact_email;
            this.address = tender_detail.address;
            this.start_date = tender_detail.start_time;
            this.end_date = tender_detail.end_time;
            this.bid_start_date = tender_detail.bid_start_time;
            this.bid_end_date = tender_detail.bid_end_time;

            this.start_date_display = tender_detail.start_time_display;
            this.end_date_display = tender_detail.end_time_display;
            this.bid_start_date_display = tender_detail.bid_start_time_display;
            this.bid_end_date_display = tender_detail.bid_end_time_display;
            this.auction_status = tender_detail.live_for_bid;
            this.winner_an = tender_detail.winner_an;
            this.status = tender_detail.status;
            this.total_rough_carat = tender_detail.total_carat;
            this.total_bid_amount = tender_detail.total_amount;
            this.tax_amount = tender_detail.total_tax;
            this.total_amount = tender_detail.total_payable;
            if(tender_detail.bid_placed == 'yes'){
              this.bid_placed = true;
          }else{
            this.bid_placed = false;
          }
          //_this.calculateTotal();

        })
        .catch(_error => {
          
        });
    },
    downloadTenderDetails(){
      let filename = this.tender_name + "_.xlsx";
          const data = {
            td: this.tender_id
          };
           let promise = axios({
        url: "api/download_my_tender_details",
        method: "get",
        params: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
        responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
          FileDownload(data, filename);
          this.status = "success";

          setTimeout(() => {
            this.status = "default";
          }, 3000);
        })
        .catch((_error) => {
          this.status = "fail";
          setTimeout(() => {
            this.status = "default";
          }, 3000);
          return [];
        });
    },
    calculateTotal(){
      this.total_rough_carat = 0;
      this.total_bid_amount = 0;
      this.tax_amount = 0;
      this.total_amount = 0;
      var i = this.fields.length;
              while(i--){
                if(this.fields[i]['bid_price'] != '' && this.fields[i]['bid_price'] != null &&  !isNaN(this.fields[i]['bid_price'])){
                  this.total_rough_carat = this.total_rough_carat + parseFloat(this.fields[i]['carats']);
                  if(this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats']) && this.fields[i]['carats'] != null && this.fields[i]['bid_price'] != null && this.fields[i]['bid_price'] != '' && !isNaN(this.fields[i]['bid_price'])){
                    
                      this.total_bid_amount = this.total_bid_amount + (parseFloat(this.fields[i]['carats']) * parseFloat(this.fields[i]['bid_price']));
                  }
              }
            }

            if(this.total_bid_amount > 0){
              this.tax_amount = (4 * this.total_bid_amount) / 100;
              this.total_amount = this.tax_amount + this.total_bid_amount;
            }
    },
  },
  
})
</script>

<style scoped>
.border {
  margin: 0 20px;
 
}
.border-right{
  border-right: 1px solid #e3e3e3;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>