<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Bid Tender</h2>
       <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
       
       <div class="text-theme-6 pt-2 mr-2">
            <span>{{timer.days}} Days </span>: <span>{{timer.hours}} Hrs </span>: <span>{{timer.minutes}} Min </span>: <span>{{timer.seconds}} Sec</span>
        </div>

          <button
          type="button"
          @click="downloadTenderDetails"
           class="btn btn-primary shadow-md mr-2">
           Download Tender Details
          </button>
        
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Tender Details</h2>
            
          </div>
         <form @submit.prevent="onUserFormSubmit">
         <!-- <form @submit.prevent="getOtp"> -->
          <div class="p-5">
            
            <div class="grid grid-cols-12 gap-x-5">
             
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Tender Name : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ tender_name }}</label
                  >
                </div>
                
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Email : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_email }}</label
                  >
                  
                </div>
                <div class="mt-3">
                     <label for="update-profile-form-6" class="form-label font-bold"
                    >Contact Mobile No : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ contact_number }}</label
                  >
                  
                  
                </div>
                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Site Address : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ address }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6">
                
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ start_date_display }}</label
                  >
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ end_date_display }}</label
                  >
                </div>

                <div class="mt-3">
                   <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid Start Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_start_date_display }}</label
                  >
                  
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold"
                    >Bid End Date & Time : </label
                  ><label for="update-profile-form-6" class="form-label"> {{ bid_end_date_display }}</label>
                  
                </div>
              </div>
            </div>
        
          </div>
         
        <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Rough Details</h2>
        </div>
         <div v-if="bid_placed" class="ml-5 mt-5">
                  <label for="update-profile-form-6 mt-3" class="form-label font-bold text-theme-6"
                    >You have already placed bid for this tender. You can update bid price untill the the timer ends.</label
                  >
                </div>
        <div class="border">
        <div class="grid grid-cols-12 sticky top-0 z-50 pl-5 pr-5 pb-2" style="background-color:#fff;">
             
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold pt-2 ">
                <p>Sr. No</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold pt-2 ">
                <p>Cut</p>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-3 font-bold pt-2 ">
                <p>Details</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold pt-2 ">
                <p>Stone</p>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 font-bold pt-2 ">
                <p>Carats</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 font-bold pt-2 ">
            <p>Bid Price($)/Carat</p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 font-bold pt-2 ">
            <p>Total Price($)</p>
              </div>
            </div>
      
          
          <div class="border mt-5" v-for="field in fields" :key="field.id">
            
            <div class="grid grid-cols-12">
              
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pl-2" >
                <span class="pl-1 pt-3" >{{field.lot_no}}</span>
                
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1  border-right pt-2 pl-2">
                <span class="pl-1 pt-3" >{{field.cut}}</span>
               
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-3 border-right pt-2 pl-2">
                 <span class="pl-1 pt-3" >{{field.details}}</span>
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pl-2">
                <span class="pl-1 pt-3" v-if="(field.piece != '') && (field.piece != null) ">{{field.piece}}</span>
                <span class="pl-1 pt-3" v-else>-</span>
             
              </div>
              <div class="col-span-6 md:col-span-1 xl:col-span-1 border-right pt-2 pl-2">
                <span class="pl-1 pt-3" >{{field.carats}}</span>
              
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2">
             <input v-model="field.bid_price" type="text" @keypress="preventInvalidInput"
                    class="form-control" placeholder="Bid Price" onpaste="return false;" @change="calculateTotal">
              </div>
               <div class="col-span-6 md:col-span-2 xl:col-span-2 pt-2 pl-2">
                <span class="pl-1 pt-3" v-if="(!isNaN(field.bid_price * field.carats))">{{ (field.bid_price * field.carats).toFixed(2) }}</span>
              </div>

              <div class="col-span-6 md:col-span-1 xl:col-span-1 pt-3 pl-3" >
               
              <button type="button" class="text-theme-9 flex items-center ml-5" v-if ="field.attachment == 'yes'" @click="downloadLink(field.attachment_link)">
                <ExternalLinkIcon class="w-4 h-4 mr-1" />Galaxy File
              </button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total weight(Carat) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_rough_carat.toFixed(2)}}</p>
              </div>

          </div>
           <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total Bid Price($) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_bid_amount.toFixed(2)}}</p>
              </div>

          </div>
          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">4 % Charges ($) : </p>
              </div>
              <div class="col-span-6  md:col-span-2 xl:col-span-2 text-right">
                <p>{{tax_amount.toFixed(2)}}</p>
              </div>

          </div>

          <div class="grid grid-cols-12 mt-3 pr-5">
             <div class="col-span-12 md:col-span-7 xl:col-span-7">
                
              </div>
               <div class="col-span-6 md:col-span-3 xl:col-span-3 text-right">
                <p class="font-bold">Total Payable ($) : </p>
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-2 text-right">
                <p>{{total_amount.toFixed(2)}}</p>
              </div>

          </div>
       
        </div>
        <div>
        <div class="p-5">
            <div class="flex justify-end mt-4">
              <button type="submit" class="btn btn-primary mr-auto" >
                
                <span v-if="bid_placed">
                 Update Bid
                </span>
                <span v-else>
                 Place Bid
                </span>
                
              </button>
              
            </div>
        </div>
          </div>
         </form>
        </div>


    <div id="new-modal-contact" class="modal" data-backdrop="static" tabindex="-1" aria-hidden="true" ref="contact-modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Confirm Contact Details</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            
              <div class="col-span-12 xl:col-span-6">
                
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Bidder Name</label
                  >
                   <input v-model="verifyContactform.person_name" type="text" 
                    class="form-control" placeholder="Full Name">
                </div>
                
                
              </div>
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Comapny Name</label
                  >
                  <input v-model="verifyContactform.company_name" type="text" 
                    class="form-control" placeholder="Company Name">
                 
                </div>
             
              </div>

               <div class="col-span-12 xl:col-span-6">
                
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Registed Mobile No</label
                  >
                   <input v-model="verifyContactform.opt_number" type="text" 
                    class="form-control" placeholder="" disabled>
                </div>
                
                
              </div>
              <div class="col-span-12 xl:col-span-6">
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Bidder Mobile No</label
                  >
                  <input v-model="verifyContactform.contact_no" type="text" 
                    class="form-control" placeholder="10 digit mobile no">
                 
                </div>
             
              </div>
               <div class="col-span-12 xl:col-span-6">
                
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Email Address</label
                  >
                   <input v-model="verifyContactform.email_address" type="text" 
                    class="form-control" placeholder="buyer@domain.com">
                </div>
                
                
              </div>
              
              <div class="col-span-12 xl:col-span-6">
                
              </div>

               <div class="col-span-12 xl:col-span-12">
                <p class="font-bold">Kindly confirm all the above details and "Registered Mobile No", you will be getting OTP to confirm tender bid on same number. If your Registered mobile no. is incorrect kindly contact DK Tender Admin to update details else you will not be able to place bid.
                </p>
              </div>
              
            
          </div>
          <div class="modal-footer text-right">
            
            <button type="button" class="btn btn-primary w-32" @click="submitVerifyContact">
              Confirm Details
            </button>
           
          </div>
        </div>
      </div>
    </div>


    <div id="new-modal-otp" class="modal" data-backdrop="static" tabindex="-1" aria-hidden="true" ref="otp-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Confirm OTP</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            
            <div class="col-span-12 xl:col-span-12">
                
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Registed Mobile No</label
                  >
                   <input v-model="verifyContactform.opt_number" type="text" 
                    class="form-control" placeholder="" disabled>
                </div>
                
                
              </div>
              
               <div class="col-span-12 xl:col-span-12">
                
                
                <div class="">
                  <label for="update-profile-form-7" class="form-label"
                    >Received OTP</label
                  >
                   <input v-model="verify_otp" type="text" 
                    class="form-control" placeholder="One Time Password">
                </div>
                
                
              </div>
              
              <div class="col-span-12 xl:col-span-6">
                
              </div>

               <div class="col-span-12 xl:col-span-12">
                <p class="font-bold">You will be getting OTP to confirm tender bid on your registered number. If your Registered mobile no. is incorrect kindly contact DK Tender Admin to update details else you will not be able to place bid.
                </p>
              </div>
              
            
          </div>
          <div class="modal-footer text-right">
            
            <button type="button" class="btn btn-primary" @click="onUserFormSubmit">
              Confirm OTP & Place Bid
            </button>
           
          </div>
        </div>
      </div>
    </div>

        
        <div id="success-notification-bid-success" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Bid Placed Successfully!</div>
        </div>
         </div>

          <div id="success-notification-contact-success" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Contact Details updated Successfully!</div>
        </div>
         </div>

         <div id="success-notification-bid-expired" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-6"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">Oops ! You can not place new bid. Tender Auction is not live!</div>
        </div>
         </div>

        <div id="failed-notification-bid" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Oh No!</div>
            <div class="text-gray-600 mt-1">
                Something gone wrong! Please Check the data, and try again.
            </div>
        </div>
        </div>
        

        <div id="failed-notification-invalid-otp" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Oh No!</div>
            <div class="text-gray-600 mt-1">
                OTP Does not match with server OTP. Kindly try again.
            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
      
    </div>
  </div>
</template>


<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import datetime from 'vuejs-datetimepicker';
import { useTimer } from 'vue-timer-hook';
import FileDownload from "js-file-download";

export default defineComponent({

  components: {
    DarkModeSwitcher,
    datetime
  },
  props: {
    tender_id: {
      type: String,
      default: ""
    }
  },
  
  data() {

    let time = new Date();
    let timer = useTimer(time);

    return {
      timer,
      tender_update_id : '',
      tender_name : '',
      total_rough_carat : 0,
      total_bid_amount: 0,
      tax_amount: 0,
      total_amount: 0,
      bid_placed:false,
      contact_number: '',
      contact_email: '',
      address: '',
      start_date: '',
      end_date: '',
      bid_start_date: '',
      bid_end_date: '',
      start_date_display: '',
      end_date_display: '',
      bid_start_date_display: '',
      bid_end_date_display: '',
      total_items:1,
      fields: [],
      contact_verify:'',
      verifyContactform:{
        person_name:'',
        reg_mobile_no:'',
        company_name:'',
        contact_no:'',
        email_address:'',
        opt_number:''
      },
      verify_otp:"",
      recived_otp_hash:"",
      status: 'default',
      auction_live:'',
   
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    
  },
  mounted(){
  
  this.getTenderDetails(this.tender_id);

  },

  methods: {
    getTenderDetails(tender_id) {
      let _this = this;
      const params = {
        tender_id: tender_id
      };
      let promise = axios({
        url: "api/buyer_tender_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          
          let tender_detail = data.tender_detail;
          let buyer_detail = data.buyer_details;

          let time = new Date(tender_detail.bid_end_time);
          
          this.timer.restart(time);
          this.tender_name = tender_detail.tender_name;
          this.fields = tender_detail.rough_details_arr;
          this.contact_number= tender_detail.contact_number;
          this.contact_email= tender_detail.contact_email;
          this.address = tender_detail.address;
          this.start_date = tender_detail.start_time;
          this.end_date = tender_detail.end_time;
          this.bid_start_date = tender_detail.bid_start_time;
          this.bid_end_date = tender_detail.bid_end_time;
          this.start_date_display = tender_detail.start_time_display;
          this.end_date_display = tender_detail.end_time_display;
          this.bid_start_date_display = tender_detail.bid_start_time_display;
          this.bid_end_date_display = tender_detail.bid_end_time_display;
          this.status = tender_detail.status;
          this.contact_verify = tender_detail.contact_verify;
          this.auction_live = tender_detail.live_for_bid;
          this.verifyContactform.opt_number = buyer_detail.company_contact_no;
          if(this.auction_live == 'no'){
            Toastify({
              node: cash('#success-notification-bid-expired')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
                
            setTimeout(() => {
                  this.$router.push("/app/tender/list");
                }, 500)
          }
          if(tender_detail.bid_placed == 'yes'){
              this.bid_placed = true;   
          }else{
            this.bid_placed = false;
          }

          
          if(this.contact_verify == 'no'){
            //console.log("Yes" + this.contact_verify);
            cash('#new-modal-contact').modal('show');
            //this.$refs["contact-modal"].show();
          }
          _this.calculateTotal();
          

        })
        .catch(_error => {
          
        });
    },
    downloadLink(filelink){
      window.open(filelink, '_blank');
    },
    onUserFormSubmit() {
      //console.log(JSON.stringify(this.gridForm));
      this.errors = [];
      // let decode_otp = window.atob(this.recived_otp_hash);

      // if(this.verify_otp == decode_otp){
        
      // }else{
      //  this.errors.push("Contact name required.");
      //   Toastify({
      //     node: cash('#failed-notification-invalid-otp')
      //       .clone()
      //       .removeClass('hidden')[0],
      //     duration: 5000,
      //     newWindow: true,
      //     close: true,
      //     gravity: 'top',
      //     position: 'right',
      //     stopOnFocus: true
      //   }).showToast()
      //   return;
      // }

      
      if (!this.errors.length) {
       
      this.$swal({
        title: "",
        text: "You are about to Place a Auction Bid!",
        type: "warning",
        html:`<div style="text-align:left;"><strong>Please Note:</strong><br><br>
        1. OFFERS ARE ONLY IN CASH PRICE.<br>
        2. 4% WILL BE ADDED TO OFFER PRICE AS COMMISSION TO OFFICE INCLUDING GST<br>
        3. NO ARGUMENTS WILL BE ENTERTAINED AFTER SUBMITTING THE FINAL BIDS.<br>
        4. MORE THAN 2 PERSONS ARE NOT ALLOWED FROM ANY COMPANY IN TENDER.<br>
        5. DELIVERY SHOULD BE TAKEN WITHIN 3 DAYS AFTER RESULT DECLARATION.<br>
        6. BROKRAGE WOULD BE PAID BY THE BIDDER.<br>
        7. INTEREST WILL BE CHARGED 1% IF PAYMENT GETS DELAYED BEYOND 10 DAYS.<BR>
        8. AFTER WINNING A PARTICULAR LOT IF PARTY REFUSES TO TAKE DELIVERY THEN WILL HAVE TO PAY 10% AS PENALTY.
        </div>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree Terms & Place Bid",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
          bid_details:this.fields,
          td:this.tender_id
          };
          if(this.tender_id != ''){
            this.url = "api/buyer_tender_bid"
          }
          let promise = axios({
            url: this.url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.bid_status == 'placed'){
                cash('#new-modal-otp').modal('hide');
              Toastify({
              node: cash('#success-notification-bid-success')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
                
            setTimeout(() => {
                  this.$router.go("/app/tender/list");
                }, 500)
              }
              else if(response.data.bid_status == 'expired'){
                cash('#new-modal-otp').modal('hide');
                Toastify({
              node: cash('#success-notification-bid-expired')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
                
            setTimeout(() => {
              
                  this.$router.go("/app/tender/list");
                }, 500)
              }
            
              
            })
            .catch(_error => {
              Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();

        cash('#new-modal-otp').modal('hide');
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          
        }
      });

      }
    },
    preventInvalidInput(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    }
    ,calculateTotal(){
      this.total_rough_carat = 0;
      this.total_bid_amount = 0;
      this.tax_amount = 0;
      this.total_amount = 0;
      var i = this.fields.length;
              while(i--){
                if(this.fields[i]['bid_price'] != '' && this.fields[i]['bid_price'] != null &&  !isNaN(this.fields[i]['bid_price'])){
                  this.total_rough_carat = this.total_rough_carat + parseFloat(this.fields[i]['carats']);
                  if(this.fields[i]['carats'] != '' && !isNaN(this.fields[i]['carats']) && this.fields[i]['carats'] != null && this.fields[i]['bid_price'] != null && this.fields[i]['bid_price'] != '' && !isNaN(this.fields[i]['bid_price'])){
                    
                      this.total_bid_amount = this.total_bid_amount + (parseFloat(this.fields[i]['carats']) * parseFloat(this.fields[i]['bid_price']));
                  }
              }
            }

            if(this.total_bid_amount > 0){
              this.tax_amount = (4 * this.total_bid_amount) / 100;
              this.total_amount = this.tax_amount + this.total_bid_amount;
            }
    },
    downloadTenderDetails(){
      let filename = this.tender_name + "_.xlsx";
          const data = {
            td: this.tender_id
          };
           let promise = axios({
        url: "api/download_my_tender_details",
        method: "get",
        params: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } },
        responseType: "blob",
      });

      return promise
        .then((result) => result.data)
        .then((data) => {
          FileDownload(data, filename);
          this.status = "success";

          setTimeout(() => {
            this.status = "default";
          }, 3000);
        })
        .catch((_error) => {
          this.status = "fail";
          setTimeout(() => {
            this.status = "default";
          }, 3000);
          return [];
        });
    },
    submitVerifyContact(){

    
    this.errors = [];

    if (!this.verifyContactform.person_name) {
        this.errors.push("Contact name required.");
        Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }

      if (!this.verifyContactform.company_name) {
        this.errors.push("Contact name required.");
        Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }
      if (!this.verifyContactform.contact_no) {
        this.errors.push("Contact name required.");
        Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }
      if (!this.verifyContactform.email_address) {
        this.errors.push("Contact name required.");
        Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        return;
      }

      if (!this.errors.length) {
       
      this.$swal({
        title: "",
        text: "You are about to confirm contact details for Auction!",
        type: "warning",
        html:`<div style="text-align:left;"><strong>Please Note:</strong><br><br>
        Details once updated can not be changed for entire span of tender.<br></div>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Agree & Confirm",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
          person_name:this.verifyContactform.person_name,
          company_name:this.verifyContactform.company_name,
          contact_no:this.verifyContactform.contact_no,
          email_address:this.verifyContactform.email_address,
          opt_number:this.verifyContactform.opt_number,
          td:this.tender_id
          };
          if(this.tender_id != ''){
            this.url = "api/buyer_tender_contact"
          }
          let promise = axios({
            url: this.url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == 'updated'){
              Toastify({
              node: cash('#success-notification-contact-success')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
                
            setTimeout(() => {
                  cash('#new-modal-contact').modal('hide');
                }, 500)
              }
              else if(response.data.bid_status == 'expired'){
                Toastify({
              node: cash('#success-notification-bid-expired')
                .clone()
                .removeClass('hidden')[0],
              duration: 5000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
                
            setTimeout(() => {
                  
                }, 500)

              }
            
              
            })
            .catch(_error => {
              Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          
        }
      });

      }
    },
    getOtp(){
      this.errors = [];

      if (!this.errors.length) {
       
          const data = {
          td:this.tender_id
          };
          if(this.tender_id != ''){
            this.url = "api/get_tender_otp"
          }
          let promise = axios({
            url: this.url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == 'updated'){
              this.recived_otp_hash = data.hash;  
              setTimeout(() => {
                
                  cash('#new-modal-otp').modal('show');
                }, 500)
              }
              
              
            })
            .catch(_error => {
              Toastify({
          node: cash('#failed-notification-bid')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
            });

      }

    }
  },
  
})
</script>

<style scoped>

.border {
  margin: 0 20px;
  
}
.border-right{
  border-right: 1px solid #e3e3e3;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>