<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Cleaver Account</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Cleaver Details</h2>
            
          </div>
         
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >First Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    value=""
                    
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Middle Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Middle name"
                    value=""
                    
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label"
                    >Last Name</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    value=""
                    
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Personal Email</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="example@domain.com"
                    value=""
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Mobile No</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder="+00 00000 00000"
                    value=""
                  />
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-9" class="form-label"
                    >Process</label
                  >
                  <input
                    id="update-profile-form-9"
                    type="text"
                    class="form-control"
                    placeholder=""
                    value=""
                  />
                </div>
              </div>
             
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-primary w-20 mr-auto">
                Save
              </button>
              <a href="" class="text-theme-6 flex items-center">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
              </a>
            </div>
         
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>
