<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rapaport Rate</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <!-- <router-link
          :to="{ name: 'add-shape' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
        
        Add New Shape
          </router-link> -->

          <a
          href="javascript:;"
          class="btn btn-primary shadow-md mr-2"
          >Sync New Rates</a
        >
        
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">
        <div id="single-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneSingleRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  maxFiles: 1,
                  headers: { 'My-Awesome-Header': 'header value' }
                }"
                class="dropzone mb-4"
              >
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  Upload Excel file with latest Rapaport rate downloaded from official website. (.xlsx, .xls)
                </div>
              </Dropzone>
              <span class="mt-5">Latest Rates upadted on : 11/07/2021 11:00:00</span>
            </div>
            
          </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, provide, onMounted } from 'vue'

export default defineComponent({
  setup() {
    const dropzoneSingleRef = ref()
    const dropzoneMultipleRef = ref()
    const dropzoneValidationRef = ref()

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    provide('bind[dropzoneMultipleRef]', el => {
      dropzoneMultipleRef.value = el
    })

    provide('bind[dropzoneValidationRef]', el => {
      dropzoneValidationRef.value = el
    })

    onMounted(() => {
      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })

      const elDropzoneMultipleRef = dropzoneMultipleRef.value
      elDropzoneMultipleRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneMultipleRef.dropzone.on('error', () => {
        alert('No more files please!')
      })

      const elDropzoneValidationRef = dropzoneValidationRef.value
      elDropzoneValidationRef.dropzone.on('success', () => {
        alert('Added file.')
      })
      elDropzoneValidationRef.dropzone.on('error', () => {
        alert('No more files please!')
      })
    })
  }
})
</script>

