const state = () => {
  return {
      menu: [{
              icon: "HomeIcon",
              pageName: "manager-dashboard",
              title: "Dashboard",
              subMenu: [{
                      icon: "",
                      pageName: "manager-dashboard",
                      title: "Buyer Dashboard"
                  },
              ]
          },
        
          "devider",

          {
              icon: "BoxIcon",
             // pageName: "app-masters",
              pageName: "tenders",
              title: "Tenders",
          },
         
      ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};