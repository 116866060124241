<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <div style="width:90%;text-align:center;">
            <img
              alt="DK Sons"
              style="width:10rem;display:unset;"
              :src="require(`@/assets/images/bm/logo-02.png`)"
            />
           </div>
          </a>
          <div class="my-auto text-center">
            <img
              alt="Blue Moon - Gem Stone Management"
              class="-intro-x -mt-10"
              style="width:100%"
              :src="require(`@/assets/images/bm/kindpng_129593.png`)"
            />
            <div
              class="-intro-x text-white font-medium text-2xl leading-tight mt-10"
            >
              DK Tender Present
            </div>
            <div
              class="-intro-x mt-5 text-4xl text-white text-opacity-70 dark:text-gray-500"
            >
              Congo Original Goods
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
         <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              
            <img
              alt="DK Sons"
              style="width:10rem;display:unset;"
              :src="require(`@/assets/images/bm/logo-02.png`)"
            />
          
            </div>
        <div class="xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h3
              class="intro-x text-2xl xl:text-3xl text-center xl:text-left mt-4"
            >
              Recover Password
            </h3>
          
            <form @submit.prevent="formSubmit">
            <div class="intro-x mt-8">

              <input
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
                 v-model="email"
              />
            </div>
          
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
               <router-link
          :to="{ name: 'login' }"
          tag="link"
           class="mr-2 btn btn-secondry  py-3 px-4 w-full xl:w-32 mb-5  cursor-pointer">
           Back to Login
          </router-link>
              <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" type="submit">
                Recover
              </button>
            </div>
            </form>
            <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Login Successfuly!</div>
            <div class="text-gray-600 mt-1">
                Welcome to DK Rough Tender System.
            </div>
        </div>
    </div>

    <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Invalid Login!</div>
            <div class="text-gray-600 mt-1">
                Please Check Username and Password and try again
            </div>
        </div>
    </div>

          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import feather from 'feather-icons'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    feather
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
      
    })
  },
  data() {
    return {
      status: 'default',
      email: '',
      password: '',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'processing', 'loginError'])
  },

  methods: {
    ...mapActions(['login']),
    formSubmit() {
      this.status = 'processing'
      this.$swal({
        title: "Are you sure?",
        text: "Make sure the provided email is correct!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Recover",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {
          const data = {
            username: this.email
          };
          let promise = axios({
            url: "api/recover_password",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data.message)
            .then(data => {
              this.$swal("Success!", "Kindly check your email for new password!", "success");
              this.$router.push('/')
            })
            .catch(_error => {
              this.$swal("Error!", "No User Found with provided email address.Please enter correct email address and try again!", "error");
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        
      });
    }
  },
  watch: {
    currentUser(val) {
      if (val != null) {
        this.status = 'success'
        Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push('/')
        }, 500)
      }
    },
    loginError(val) {
      if (val != null) {
        this.status = 'fail'
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        setTimeout(() => {
          this.status = 'default'
        }, 2000)
      }
    }
  }

})
</script>
