<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rough Tenders</h2>
     
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
          
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'name',
      type: 'like',
      value: ''
    })

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + '/api/list_my_tenders',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [ 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Tender Name',
            minWidth: 200,
            responsive: 0,
            field: 'tender_name',
            vertAlign: 'middle',
            print: false,
            download: false,
            cellClick:function(e, cell){
              //e - the click event object
              //cell - cell component
              console.log(cell.getData().id);
              if(cell.getData().live_for_bid == 'yes'){
                router.push("/app/tender/bid/" + window.btoa(cell.getData().id));
              }else{
                router.push("/app/tender/view/" + window.btoa(cell.getData().id));
              }
              
            },
            formatter(cell) {
             
              return `<div>
                <div class="font-medium whitespace-nowrap">${
                  cell.getData().tender_name
                }</div>
               
              </div>`
            }
          },
         
          {
            title: 'Start Date',
            minWidth: 200,
            field: 'start_time',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'End Date',
            minWidth: 200,
            field: 'end_time',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            minWidth: 200,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().live_for_bid == 'yes'){
                return `<div class="flex items-center lg:justify-center blink text-theme-9 font-bold">
                <i data-feather="activity" class="w-4 h-4 mr-2"></i> Tender Live
              </div>`
              }else{

              return `<div class="flex items-center lg:justify-center ${
                cell.getData().active_status ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="${
                cell.getData().active_status ? 'eye' : 'alert-circle'
              }" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status ? 'Active' : 'Expired'
                }
              </div>`
              }
            }
          },
          {
            title: 'Actions',
            minWidth: 200,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const data_id = cell.getData().id;

              if(cell.getData().live_for_bid == 'yes'){
              // const a = cash(`<div class="flex lg:justify-center items-center"><i data-feather="eye" class="w-4 h-4 mr-2"></i>View Details</div>`)

            
              // cash(a).on('click', function(data_id) {
              //   // On click actions
              //   router.push("/app/tender/view/" + window.btoa(cell.getData().id));
              // })

              // return a[0] 
              }
              else if(cell.getData().status == 2){
              //   const a = cash(`<div class="flex lg:justify-center items-center"><i data-feather="eye" class="w-4 h-4 mr-2"></i>View Details</div>`)

            
              // cash(a).on('click', function(data_id) {
              //   // On click actions
              //   router.push("/app/tender/view/" + window.btoa(cell.getData().id));
              // })

              // return a[0] 
              }
              else{
                const a = cash(`<div class="flex lg:justify-center items-center text-theme-6"><i data-feather="clock" class="w-4 h-4 mr-2"></i>`
                + cell.getData().bid_start_time +`
              </div>`)

            
              cash(a).on('click', function(data_id) {
                // On click actions
              })

              return a[0] 
              }
              
            }
          },

          // For print format
          {
            title: 'Shape Name',
            field: 'name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Shape Code',
            field: 'remaining_stock',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Status',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },
          {
            title: 'IMAGE 1',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue()[0]
            }
          },
          {
            title: 'IMAGE 2',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue()[1]
            }
          },
          {
            title: 'IMAGE 3',
            field: 'images',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue()[2]
            }
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })

    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  }
})
</script>