
<template>

  <div>
    <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-10">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/bm/login_info.svg`)"
          />
        </div>
        <div class=" mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium">No Access</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            Oops. You don't have sufficent access to view this page.
          </div>
          <div class="intro-x text-lg mt-3">
            Kindly contact admin if you are seeing this page unexpectedly.
          </div>
          
        </div>
      </div>
   
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const categories = ref(['1', '2'])
    const tags = ref(['1', '2'])
    const salesReportFilter = ref('')
    const editorData = ref('<p>Content of the editor.</p>')

    return {
      categories,
      tags,
      salesReportFilter,
      editorData
    }
  }
})
</script>
